import client from './instance'
import { createBy, updateBy } from 'utils/apiHelper'
import { isFile } from 'utils/miscHelper'
import client2 from './instanceNoLodding'
 

export const getCnfParameters = async (data) => {
  try {
    const response = await client.post('/cnf-parameter/getCnfParameters', data);

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
};

export const getCnfParameterById = async (id) => {
  try {
    const response = await client.post('/cnf-parameter/getCnfParameterById', { id });

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const getCnfParameterBy = async (data) => {
  try {
    const response = await client2.post('/cnf-parameter/getCnfParameterBy', data);
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const createCnfParameter = async (data) => {
  try {
    const formData = new FormData();
    formData.append("cmContent", JSON.stringify(createBy(data)))
    if (isFile(data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'T')]?.cmContentChannelInfoBody.thumbnailFileUrl)) {
      formData.append("T-thumbnailFileUrl", data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'T')].cmContentChannelInfoBody.thumbnailFileUrl)
    }
    if (isFile(data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'T')]?.cmContentChannelInfoBody.videoFileUrl)) {
      formData.append("T-videoFileUrl", data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'T')].cmContentChannelInfoBody.videoFileUrl)
    }

    if (isFile(data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'C')]?.cmContentChannelInfoBody.thumbnailFileUrl)) {
      formData.append("C-thumbnailFileUrl", data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'C')].cmContentChannelInfoBody.thumbnailFileUrl)
    }
    if (isFile(data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'C')]?.cmContentChannelInfoBody.videoFileUrl)) {
      formData.append("C-videoFileUrl", data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'C')].cmContentChannelInfoBody.videoFileUrl)
    }

    if (data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'C')]?.cmContentChannelInfoButton.length) {
      const cmContentChannelInfoButton = data.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'C')].cmContentChannelInfoButton
      for (const i in cmContentChannelInfoButton) {
        if (isFile(cmContentChannelInfoButton[i].logoFileUrl)) {
          formData.append(`A-${i}-logoFileUrl`, cmContentChannelInfoButton[i].logoFileUrl)
        }
      }
    }

    const response = await client.post('/cnf-parameter/createCnfParameter', formData);

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const updateCnfParameter = async (data) => {
  try {
    const formData = new FormData();
    formData.append("cmContent", JSON.stringify(updateBy(data)))
    if (isFile(data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'T')]?.cmContentChannelInfoBody.thumbnailFileUrl)) {
      formData.append("T-thumbnailFileUrl", data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'T')].cmContentChannelInfoBody.thumbnailFileUrl)
    }
    if (isFile(data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'T')]?.cmContentChannelInfoBody.videoFileUrl)) {
      formData.append("T-videoFileUrl", data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'T')].cmContentChannelInfoBody.videoFileUrl)
    }
    if (isFile(data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'C')]?.cmContentChannelInfoBody.thumbnailFileUrl)) {
      formData.append("C-thumbnailFileUrl", data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'C')].cmContentChannelInfoBody.thumbnailFileUrl)
    }
    if (isFile(data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'C')]?.cmContentChannelInfoBody.videoFileUrl)) {
      formData.append("C-videoFileUrl", data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'C')].cmContentChannelInfoBody.videoFileUrl)
    }
    if (data?.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'C')]?.cmContentChannelInfoButton.length) {
      const cmContentChannelInfoButton = data.cmContentChannelInfo[data.cmContentChannelInfo.findIndex(val => val.channelType === 'C')].cmContentChannelInfoButton
      for (const i in cmContentChannelInfoButton) {
        if (isFile(cmContentChannelInfoButton[i].logoFileUrl)) {
          formData.append(`A-${i}-logoFileUrl`, cmContentChannelInfoButton[i].logoFileUrl)
        }
      }
    }

    if (data.campaignImage) formData.append("campaign_image", data.campaignImage)

    const response = await client.post('/cnf-parameter/updateCnfParameter', formData);

    return response.data;



    // const response = await client.post('/cnf-parameter/updateCnfParameter', data);

    // return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const activeCnfParameters = async (data) => {
  try {
    const response = await client.post('/cnf-parameter/activeCnfParameters', data);

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}

export const deleteCnfParameters = async (data) => {
  try {
    const response = await client.post('/cnf-parameter/deleteCnfParameters', data);

    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
}